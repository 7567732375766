/*-----------------------------------------
*  Front Page Header
-----------------------------------------*/

.new-front-page {
  color: #000;

  .header-block {
    padding: 55px 0;
    height: auto;
    background: rgb(106, 68, 201);
    background: radial-gradient(circle, rgba(106, 68, 201, 1) 0%, rgba(128, 104, 188, 1) 41%, rgba(64, 30, 151, 1) 100%);

    &--second {
      background: linear-gradient(to top left, #B294FB 10%, #5625CF 80%);

      .container {
        padding-top: 0px !important;
      }

      h3 {
        font-family: 'Open Sans', 'Montserrat', sans-serif;
        font-size: 24px !important;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        text-shadow: 0px 2px 12px rgba(11, 9, 89, 0.3);
        padding-top: 20px;

        @media (min-width: 200px) and (max-width: 1199px) {
          font-size: 1.3em !important;
          font-weight: 700;
          line-height: 29px;
        }
      }

      .two-col ul li,
      .one-col ul li {
        font-size: 20px;
        font-family: Pt Sans;
      }
    }

    &--three {
      padding: 100px;
      background: linear-gradient(101.53deg, #5625CF 3.86%, #B294FB 99.99%);

      .header-block-present-quotes {
        @media (max-width: 1400px) {
          flex-direction: column;
        }
      }

      .container {
        padding-top: 0px !important;
      }

      .header-block-present-sections-block-quote:first-child {
        padding-right: 10px;
        min-width: 880px;
        margin-top: 0px;

        @media (min-width: 0px) and (max-width: 1200px) {
          min-width: auto;
        }
      }

      h1 {
        padding-bottom: 50px;
        @media (min-width: 200px) and (max-width: 1200px) {
          padding-bottom: 20px;
        }
      }

      .img-container img {
        padding-left: 30px;
        height: 430px;
        max-width: none;

        @media (min-width: 200px) and (max-width: 1400px) {
          height: auto;
          width: 100%;
          padding-left: 0;
          padding-top: 20px;
        }
      }

      .cells {
        height: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 50px;
        row-gap: 30px;
        padding-inline-start: 20px;

        @media (min-width: 200px) and (max-width: 767px) {
          grid-template-columns: repeat(1, 1fr);
          row-gap: 10px;
        }

        @media (min-width: 768px) and (max-width: 1200px) {
          grid-template-columns: repeat(2, 1fr);
          row-gap: 20px;
        }

        li {
          font-family: PT Sans;
          font-size: 22px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;

          @media (min-width: 768px) and (max-width: 1200px) {
            font-size: 20px;
          }
          @media (min-width: 200px) and (max-width: 767px) {
            font-size: 17px;
          }
        }
      }
    }

    @media (max-width: 769px) {
      padding: 60px 0;
    }

    @media (min-width: 200px) and (max-width: 1200px) {
      height: auto;
    }

    &:last-child {
      padding-bottom: 0px;
    }

    @media (max-width: 992px) {
      padding-bottom: 30px;
      padding-top: 30px;
    }

    .container {
      padding-top: 100px;

      @media (max-width: 992px) {
        padding-top: 70px;
      }
    }
  }

  .header-block-present-quotes {
    color: #ffffff;
    font-family: Pt Sans;
    // font-size: 24px;
    line-height: 29px;
    text-align: left;
    display: flex;
    flex-direction: row;

    @media (max-width: 1199px) {
      flex-direction: column;
    }

    @media (min-width: 200px) and (max-width: 1199px) {
      // font-size: 20px;
      line-height: 25px;
    }

    h1, h2 {
      font-family: 'Open Sans', 'Montserrat', sans-serif;
      font-size: 39px;
      font-weight: 800;
      line-height: 53px;
      letter-spacing: 0em;
      text-align: left;
      text-shadow: 0px 2px 12px rgba(11, 9, 89, 0.3);

      @media (min-width: 768px) and (max-width: 1200px) {
        font-size: 32px;
        line-height: 38px;
      }

      @media (min-width: 200px) and (max-width: 767px) {
        font-size: 24px;
        line-height: 32px;
      }

    }

    @media (min-width: 200px) and (max-width: 1199px) {
      .wrap-list div {
        text-align: left;
      }
    }

    .two-col ul {
      font-size: 1.7em;
      padding-top: 30px;
      list-style-position: outside;
      -moz-column-count: 2;
      /* Firefox */
      -webkit-column-count: 2;
      /* Safari and Chrome */
      column-count: 2;
      list-style-type: disc;
      height: auto;
      padding-inline-start: 20px;

      li {
        padding-bottom: 25px;

        @media (min-width: 200px) and (max-width: 969px) {
          padding-bottom: 10px;
        }
      }

      @media (min-width: 200px) and (max-width: 969px) {
        column-count: 1;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        padding-left: 20px;
        padding-top: 30px;
        font-size: 1.3em;

      }
    }

    .one-col ul {
      padding-top: 20px;
      list-style-position: outside;
      list-style-type: disc;
      height: auto;
      padding-inline-start: 25px;
      font-size: 1.7em;

      li {
        padding-bottom: 15px;

        @media (min-width: 200px) and (max-width: 969px) {
          padding-bottom: 10px;
        }
      }

      @media (min-width: 200px) and (max-width: 969px) {
        padding-left: 20px;
        padding-top: 30px;
        font-size: 1.3em;
      }
    }

    .fon-video-5 {
      position: relative;
      margin: 70px -80px;
      transform: rotate(8deg);
      /* Equal to rotateZ(45deg) */

      @media (min-width: 200px) and (max-width: 1200px) {
        margin: auto;
        transform: none;
        margin-top: 30px;
      }
    }

    .fon-video-5 .tv-fon {
      position: relative;
      z-index: 1;
      font: 0/0 a;
    }

    .fon-video-5 .tv-fon img {
      max-width: none;
      width: 1000px;

      @media (min-width: 200px) and (max-width: 1200px) {
        width: 100%;
      }
    }

    .fon-video-5 video {
      width: 78%;
      position: absolute;
      top: 5%;
      left: 11%;
    }

    .services__buttons {
      padding-top: 30px;
    }
  }

  .header-block-present-sections-block-quote {


    &:first-child {
      padding-right: 10px;
      min-width: 800px;
      margin-top: 40px;

      @media (min-width: 200px) and (max-width: 1199px) {
        min-width: auto;
        padding: 15px;
        margin-top: 0;
      }
    }
  }

  .button-to-form {
    text-transform: uppercase;
    border: 0px;
    border-radius: 6px;
    color: #8351FF;
    background-color: #FFFFFF;
    width: 225px;
    height: 55px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    font-family: PT Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.45));


    &:hover {
      background-color: #d971b0;
      color: #FFFFFF;
    }

    &--lilac {
      color: #FFFFFF;
      background-color: #8351FF;

      &:hover {
        background-color: #F2F2F2;
        color: #8351FF;
      }
    }

    &--pink {
      color: #FFFFFF;
      background-color: #D971B0;

      &:hover {
        background-color: #F2F2F2;
        color: #D971B0;
      }
    }

    &--lilac-not-effects {
      color: #FFFFFF;
      background-color: #8351FF;
    }
  }


  .counts {
    .container {
      max-width: 1200px;
    }

    .cells {
      display: flex;
      flex-direction: row;
      justify-content: space-between;


      @media (min-width: 0px) and (max-width: 767px) {
        height: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 15px;
        row-gap: 20px;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        justify-content: space-around;
      }


      @media (min-width: 200px) and (max-width: 1199px) {
        .count {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
      }

      .cell {
        display: flex;
        flex-direction: row;

        @media (min-width: 200px) and (max-width: 1199px) {
          flex-direction: column;
          align-items: center;
        }

        .material-icons {
          font-family: 'Material Icons' !important;
          white-space: nowrap;
          color: #976DFF;
          font-size: 48px;
          padding-right: 30px;
          line-height: 0.7 !important;

          @media (min-width: 200px) and (max-width: 1199px) {
            font-size: 50px;
            line-height: 1 !important;
            padding-right: 0;

          }
        }

        .amount {
          font-family: 'Open Sans', 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 48px;
          line-height: 0.7 !important;
          color: #24007D;
          margin-bottom: 0;

          @media (min-width: 200px) and (max-width: 1199px) {
            font-size: 25px;
            line-height: 1.6 !important;
          }
        }

        .text {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px !important;
          color: #6D56FD;

          @media (min-width: 400px) and (max-width: 1199px) {
            font-size: 15px;
          }

          @media (min-width: 200px) and (max-width: 399px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.blocks-container {
  background: white;
  border-radius: 18px;
  // position: relative;

  .blocks-wrapper-buttons {
    // position: absolute;
    width: 500px;
    height: 485px;
    left: 312px;
    top: 1435px;
    background: #F2F2F2;
    box-shadow: 0px 6px 17px rgba(173, 173, 173, 0.25);
    border-radius: 10px;

    .blocks {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;

      .block {
        background-color: #111;
      }
    }
  }

}



//////////
// демостэнды //
/////////


.mediaGallery-filtration {
  .buttons-container-info {
    // max-width: 680px;
    padding-right: 100px;
    font-family: PT Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    text-align: left;


    @media (max-width: 996px) {
      // text-align: center;
    }

    @media (max-width: 767px) {
      font-size: 17px;
      line-height: 20px;
      padding-bottom: 40px;
      padding-right: 0;
    }
  }
}

.card-demos {
  border-radius: 10px;

  .post-tags a {
    color: #8351FF !important;
  }

  .team-info {
    padding-bottom: 0px !important;

    p {
      line-height: 20px;
    }
  }

  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: white;
    border-top: none;
  }

  h5 {
    font-size: 18px !important;
  }
}


/////////////////
// карусель //
/////////////////


.carousel-caption {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: .5rem;
}

.testimonials.famous-companies,
.famous-companies {
  background-color: #F4F8FB;
  color: #040343;
}

.carousel {
  width: 100%;
}

.slide-box {
  display: flex;
  justify-content: space-between;


  a {
    height: auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    filter: grayscale(100%);

    &:hover {
      filter: grayscale(10%);
    }
  }


  @media (min-width: 576px) and (max-width: 767.98px) {
    .slide-box img {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    .slide-box img {
      -ms-flex: 0 0 33.3333%;
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }

  @media (min-width: 992px) {
    .slide-box img {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

}

//////////////////////////
// functional-characteristics
//////////////////////////

.swiper .theme-hover-02:hover {
  background: #d971b0;
  border-color: #d971b0;
}

.testimonials .feature-box-02 {
  height: 100%;
  border-radius: 12px;
  border: 1px solid #eeeeee;
  padding: 10%;
  -webkit-transition: ease all 0.3s;
  transition: ease all 0.3s;

  @media (min-width: 320px) and (max-width: 1200px) {
    padding: 5%;
  }

  &:not(:hover) {
    background: #8351FF;
  }

  .icon {
    i {
      font-size: 35px;
    }

    margin: 0 0 20px;
  }

  .feature-content {
    h4 {
      font-size: 18px;
      font-weight: 700;
      margin: 0 0 15px;
    }

    p {
      margin: 0;
    }
  }

  &:hover {
    transform: translateY(-10px);

    .feature-content h4,
    p {
      color: #fff;
    }
  }
}

.white-color {
  color: #fff;
}



#myCarousel {
  img {
    // height: 50%;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .swiper-slide {
    font-family: 'Open Sans', 'Montserrat', sans-serif;
    color: #2E2C2C;

    h4 {
      font-size: 39px;
      font-weight: 700;
      line-height: 29px;
    }

    p.slogan {
      font-size: 24px;
      font-weight: 400;
      line-height: 29px;

    }

    p.text {
      font-family: PT Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 29px;
    }

    .gallery-images {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: 365px;
      overflow-y: auto;
      overflow-x: hidden;

      @media (min-width: 320px) and (max-width: 767px) {
        height: 235px;
      }
    }

    img {
      // width: 260px;
      margin: 5px;

      width: 100%;
      height: auto;
      max-width: 260px;

      @media (min-width: 320px) and (max-width: 1200px) {
        max-width: 160px;
      }
    }
  }
}

.gallery-images::-webkit-scrollbar {
  width: 3px;
  background-color: #f9f9fd;
}

.gallery-images::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #007aff;
}

.gallery-images::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-color: #f9f9fd;
}


.nav-pagination-carousel {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 50px;

  .carousel-control-prev-icon {
    background-image: url('data:image/svg+xml, %3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2224%22%20height=%2224%22%20viewBox=%220%200%2024%2024%22%3E%3Ctext%20x=%220%22%20y=%2215%22%3E\1F868%3C/text%3E%3C/svg%3E');
  }

  .carousel-control-next-icon {
    background-image: url('data:image/svg+xml, %3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%2224%22%20height=%2224%22%20viewBox=%220%200%2024%2024%22%3E%3Ctext%20x=%220%22%20y=%2215%22%3E\1F86A%3C/text%3E%3C/svg%3E');
  }

  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators {
    position: relative;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 20px;
  }

  .carousel-indicators {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    width: 90%;

    li {
      background-color: #D9D9D9;
      height: 2px;
      margin: 0;
      flex-grow: 1;

      &.active {
        background-color: #8351FF;
      }
    }
  }

  .group-icons {
    display: flex;
    flex-direction: row;
  }
}


a.black-white {
  height: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  filter: grayscale(100%);

  &:hover {
    filter: grayscale(1%);
  }
}


h3.heading {
  font-family: 'Open Sans', 'Montserrat', sans-serif;
  font-size: 39px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  color: #2E2C2C;
  margin: 0 0 20px;

  @media (max-width: 1200px) {
    // text-align: center;
    font-size: 24px;
    line-height: 24px;
  }

  &--center {
    text-align: center;

    @media (max-width: 767px) {
      text-align: left;
    }
  }
}

p.heading {
  font-family: PT Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  color: #000000;
  text-align: left;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 21px;
    // text-align: center;
  }

  &--center {
    text-align: center;

    @media (max-width: 767px) {
      text-align: left;
    }

  }

}

.our-partners {
  background-color: white;
  color: #040343;

  .row {
    margin: 0;
  }



  .person-card {
    margin-top: 0;
    /* Отступ сверху */
    margin-bottom: 5px;
    /* Отступ снизу */
    padding: 0px;
    background-color: #F4F8FB;

    .banner-text {
      padding: 30px;
    }

    .person-card-wrap-width {
      background-color: #fff;
      width: 95%;
      display: block;
      /* Блочный элемент (для старых браузеров) */
      text-align: center;
      /* Выравнивание по центру */
      margin: 0em auto;
      margin-top: 10px;
    }

    .wrapp {
      width: 200px;
      height: 150px;
      padding: 10px;
      margin: 0em auto;

      @media (max-width: 992px) {
        font-size: 40px;
        width: auto;
        height: 100px;
      }
    }

    img.logo {

      object-fit: cover;
      width: 100%;
      height: 100%;

    }
  }
}

section.blog,
section.testimonials {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #fff;

  .siniy-phone {
    background-color: #F4F8FB;
    width: 90%;
    border-radius: 15px;
  }

  .card-deck {
    margin-bottom: 10px;
    margin-right: 0;
    margin-left: 0;
  }

  .card {
    border: none;
    box-shadow: 0px 0px 7px rgb(173 173 173 / 50%);
    border-radius: 10px;

    .team-avtar {
      filter: grayscale(0%);

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0;
        transition: ease all 0.3s;
        -webkit-transition: ease all 0.3s;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 100%;
      }
    }

    .card-footer {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &:hover .team-avtar:after {
      opacity: 0.6;
    }
  }

  .card>.card-body>.card-title {
    font-family: 'Open Sans', 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    color: #444444;
  }

  .card>.card-footer {
    background-color: #fff;
    border-top: none;
    padding: 0px 20px 20px;
  }

  .card-footer-link,
  p {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .card-footer-link {
    font-size: 16px;
    color: #8351FF;
    -webkit-filter: none !important;
    filter: none !important;
  }

  p {
    letter-spacing: 0em;
  }
}

section.clients {
  background-color: rgb(244, 248, 251, 1);

  .row {
    margin: 0;
  }

  .container.client {
    padding: 0;
  }
}

.clients .container,
.our-partners .container,
.features-block .container {
  max-width: 1200px;
}

.container.client {
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .wrapp {
    width: 20%;
    height: auto;
    padding: 12px;

    // @media (min-width: 992px) and (max-width: 1200px) {
    //   width: 185px;
    //   height: 135px;
    //   padding: 6px;
    // }

    @media (min-width: 220px) and (max-width: 767px) {
      width: 32%;
      padding: 6px;
    }

  }

  img.logo {
    object-fit: cover;
    width: 100%;
    height: 100%;

  }
}


.section-form-background {
  background: url(../img/video-gallery/imac.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: local;
  background-position: top;
  min-height: auto;

  position: relative;
  z-index: 2;
}


.section-form-background {
  .container {
    padding: 0;
  }

  .contact-form {
    border-radius: 18px;
    margin: 10px;

    .row {
      padding: 10px 35px;
      align-items: center;

      @media (max-width: 767px) {
        padding: 10px 15px;
      }
    }
  }

  .form-group .form-control {
    background: none;
  }

  .form-group textarea.form-control {
    height: 84px;
  }

  .confirm-checkbox .demo-checkbox:checked~.checkbox {
    background-color: #D971B0;
  }

  .private {
    border: 1px solid rgb(212, 212, 212);
    // padding: 20px;
  }

  .radio input+span:after {
    background: #D971B0;
  }

  .add-file-input label {
    padding-left: 15px;
  }
}



/////////////
/* larger checkbox */
////////////
:root {}

:root label.checkbox-bootstrap input[type=checkbox] {
  /* hide original check box */
  opacity: 0;
  position: absolute;
  /* find the nearest span with checkbox-placeholder class and draw custom checkbox */
  /* draw checkmark before the span placeholder when original hidden input is checked */
  /* disabled checkbox style */
  /* disabled and checked checkbox style */
  /* when the checkbox is focused with tab key show dots arround */
}

:root label.checkbox-bootstrap input[type=checkbox]+span.checkbox-placeholder {
  width: 14px;
  height: 14px;
  border: 1px solid;
  border-radius: 3px;
  /*checkbox border color*/
  border-color: #737373;
  display: inline-block;
  cursor: pointer;
  // margin: 0 7px 0 -20px;
  vertical-align: middle;
  text-align: center;
}

:root label.checkbox-bootstrap input[type=checkbox]:checked+span.checkbox-placeholder {
  background: #D971B0;
}

:root label.checkbox-bootstrap input[type=checkbox]:checked+span.checkbox-placeholder:before {
  display: inline-block;
  position: relative;
  vertical-align: text-top;
  width: 5px;
  height: 9px;
  /*checkmark arrow color*/
  border: solid white;
  border-width: 0 2px 2px 0;
  /*can be done with post css autoprefixer*/
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
}

:root label.checkbox-bootstrap input[type=checkbox]:disabled+span.checkbox-placeholder {
  background: #ececec;
  border-color: #c3c2c2;
}

:root label.checkbox-bootstrap input[type=checkbox]:checked:disabled+span.checkbox-placeholder {
  background: #d6d6d6;
  border-color: #bdbdbd;
}

:root label.checkbox-bootstrap input[type=checkbox]:focus:not(:hover)+span.checkbox-placeholder {
  outline: 1px dotted black;
}

:root label.checkbox-bootstrap.checkbox-lg input[type=checkbox]+span.checkbox-placeholder {
  width: 30px;
  height: 30px;
  border: 2px solid;
  border-radius: 5px;
  /*checkbox border color*/
  border-color: #D971B0;
}

:root label.checkbox-bootstrap.checkbox-lg input[type=checkbox]:checked+span.checkbox-placeholder:before {
  width: 9px;
  height: 15px;
  /*checkmark arrow color*/
  border: solid white;
  border-width: 0 3px 3px 0;
}



.footer-light {
  .logo-name {
    display: flex;
    flex-direction: row;
    align-items: left;
    margin-bottom: 20px;

    img {
      width: 150px;
      // height: 30px;
      // margin-right: 10px;

      @media (max-width: 767px) {
        width: 100px;
      }
    }

    h4 {
      padding: 0;
      margin: 0;
    }
  }

  .fot-address p {
    i {
      color: #222222;
    }

    a {
      color: #222222;
      font-family: PT Sans;
      font-size: 16px;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: left;
    }

    a:hover {
      text-decoration: underline !important;
    }
  }

  .footer-section {

    border-top: 500px solid #F4F8FB;
    margin-top: -450px;
  }

  .footer-section .social-icons {
    li {

      a {
        font-size: 18px;
        color: #ffffff;
        border-radius: 5px;
        background: transparent;
        -webkit-transition: ease all 0.3s;
        transition: ease all 0.3s;
        // background-color: #AC8BFF;
        background: rgba(207, 146, 212, 0.8);

        &:hover {
          color: #ffffff;
          background: #AC8BFF;
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

#grid {
  display: grid;
  grid-template-rows: 1fr 1rf 1fr;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 0;
  justify-items: center;
  align-items: center;
}

#grid>div {
  padding: .5em;
  background: rgb(234, 234, 234);
  text-align: center;
}

.rectangle-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #000;
}


.stackone {
  width: 300px;
  height: 250px;
  background: rgb(106, 68, 201);
  background: linear-gradient(120.68deg, #8453FC 41.53%, #D971B0 79.51%);

  img {
    margin-top: 20px;
    z-index: 2;
    position: relative;
    height: auto;
    width: 250px;
    position: relative;
    -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
}










////////////////////
// БЛОК ПРЕИМУЩЕСТВА
///////////////////


.features__heading {
  margin: 0 0 25px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.features-block {
  padding: 80px 0;
  overflow: hidden;

  @media (max-width: 767px) {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}

.features__text {
  max-width: 660px;
  margin: 0 0 20px;
  font-family: 'Open Sans', 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 21px;
  }
}

.features__list {
  display: grid;
  padding: 0;
  margin: 60px 0 0;
  row-gap: 80px;
  list-style: none;

  @media (max-width: 767px) {
    margin-top: 40px;
    grid-template-columns: 100%;
    row-gap: 20px;
  }
}

.feature::before {
  content: "";
  grid-area: icon;
  justify-self: center;
  width: 6px;
  height: calc(100% + 100px);
  background-color: #8453FC;

  @media (max-width: 767px) {
    display: none;
  }
}

.feature__icon {
  content: "";
  grid-area: icon;
  z-index: 2;
  justify-self: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #FFFFFF;
  background-size: 50px 50px;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 20px rgba(131, 81, 255, 0.76), 0 0 0 15px #FFFFFF, 0 0 0 21px #8453FC;

  @media (max-width: 767px) {
    grid-area: 1 / 1 / 2 / 2;
    align-self: end;
    width: 60px;
    height: 60px;
    background-size: 35px 35px;
    box-shadow: 0 0 20px rgba(131, 81, 255, 0.76);
    transform: translateY(50%);
  }
}

.feature {
  display: grid;
  grid-template-columns: 1fr 244px 1fr;
  grid-template-areas: "left icon right";
  align-items: center;
  font-family: "PT Sans", "Arial", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #444444;

  @media (max-width: 767px) {
    grid-template-columns: 100%;
    grid-template-areas: none;
    row-gap: 55px;
    font-size: 14px;
    line-height: 21px;
  }

  &:first-child::before {
    transform: translateY(50%);
  }

  &:last-child::before {
    transform: translateY(-50%);
  }

  &:only-child::before {
    display: none;
  }
}

.feature__text-content {
  margin: 28px 0 0;

  @media (max-width: 767px) {
    margin-top: 0;
  }
}

.feature__heading {
  margin: 0 0 20px;
  font-family: 'Open Sans', 'Montserrat', sans-serif;
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  color: #444444;

  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 21px;
  }
}

.feature__text {
  margin: 20px 0;
}

.feature__img-wrapper {
  grid-area: left;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 370px;
  border-radius: 4px;
  background-color: #8453FC;
  background-image: linear-gradient(120.68deg, #8453FC 41.53%, #D971B0 79.51%);

  @media (max-width: 1200px) {
    height: 260px;
    border-radius: 2px;
  }

  @media (max-width: 767px) {
    grid-area: 1 / 1 / 2 / 2;
    height: 260px;
    border-radius: 2px;
  }
}

.feature__img {
  position: absolute;
  left: 50%;
  top: calc(50% - 3px);
  width: 90%;
  height: 100%;
  object-fit: scale-down;
  object-position: center;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    top: calc(50% - 18px);
  }
}

.feature:nth-child(even) {
  .feature__img-wrapper {
    grid-area: right;

    @media (max-width: 767px) {
      grid-area: 1 / 1 / 2 / 2;
    }
  }
}

.feature--cloud .feature__img {
  top: calc(50% - 23px);
  width: 115%;

  @media (min-width: 1600px) {
    width: 122%;
    height: 106%;
    top: calc(50% - 51px);
    left: calc(50% - 89px);
  }
}

.feature--cloud:nth-child(even) .feature__img {
  @media (min-width: 1600px) {
    left: calc(50% + 89px);
  }
}





/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/

.testimonials .testimonial-wrap {
  padding-left: 50px;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 30px 30px 30px 60px;
  margin: 0 15px 30px 0;
  min-height: 200px;
  box-shadow: 0px 2px 20px rgba(82, 86, 94, 0.1);
  position: relative;
  background: #fff;
}


.testimonials .swiper-pagination {
  // margin-top: 50px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #d5d7da;
  opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #976DFF;
}

@media (max-width: 767px) {
  .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
    overflow: hidden;
  }

  .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
  }

  .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }
}

.testimonials .swiper-pag-container,
.swiper_thumbnail .swiper-pag-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  padding-left: 30px;


  @media (max-width: 992px) {
    padding-top: 25px;
  }
}


.testimonials .container {
  max-width: 1200px;

  .swiper-slide {
    height: auto;
  }

}

.swiper-pagination-progressbar.swiper-pagination-horizontal {
  height: 3px;
  width: 90%;

  .swiper-pagination-progressbar-fill {
    color: #D971B0;
  }
}


.testimonials .swiper-button-next,
.testimonials .swiper-button-prev {
  position: relative;
  margin-top: 0;
  margin: 15px;
  left: none;
  right: none;
}


.testimonials .swiper {
  padding-top: 15px;
  padding-bottom: 15px;

  .swiper-slide {
    text-align: left;
  }
}



.container-swi {
  overflow: hidden;
  max-width: 1200px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  .sample-slider {
    width: 100%;
    height: 22vw;
  }

  .sample-slider .swiper-slide {
    height: calc((100% - 10px) / 4);
  }

  .sample-slider img {
    width: 100%;
  }
}

.swiper-grid>.swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-grid-column>.swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
}


.functional-characteristics {
  overflow: hidden;
  max-width: 1200px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  .swiper {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 200px) and (max-width: 1700px) {
      height: auto;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 16px;
    background: #fff;
    height: auto;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-pag-container {
    margin-top: 0;
  }
}

.integrations {
  width: 100%;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}



//////////
// Плиточный Блок с Кейсами, слайдер
///////////

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

// services

.services {
  font-family: "PT Sans", "Arial", sans-serif;
  font-weight: 400;
}

.services__container {
  display: grid;
  max-width: 1660px;
  padding: 20px 15px;
  margin: 0 auto;
}

.services__background {
  grid-area: 1 / 1 / 2 / 2;
}

.services__content {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 2;
  padding: 120px 10px;

  @media (max-width: 1099px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.services__content-inner {
  display: grid;
  grid-template-rows: 34px 1fr 34px;
  grid-template-columns: 60px auto 1fr 60px;
  align-items: start;
  max-width: 1296px;
  margin: 0 auto;

  &::before {
    content: "";
    grid-area: 1 / 2 / -1 / 4;
    height: 100%;
    border-radius: 18px;
    background-color: #FFFFFF;

    @media (max-width: 1279px) {
      grid-area: 1 / 2 / -1 / 5;
    }

    @media (max-width: 1099px) {
      display: none;
    }
  }

  @media (max-width: 1099px) {
    grid-template-rows: auto 20px 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
}

.services__menu {
  grid-area: 2 / 1 / 3 / 3;
  z-index: 2;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  color: #8351FF;
  filter: drop-shadow(0 6px 17px fade-out(#ADADAD, 0.25));

  @media (max-width: 1099px) {
    grid-area: 1 / 1 / 3 / 5;
  }

  @media (max-width: 767px) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    grid-area: auto;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

.services__menu--open {
  display: block;
}

.services__menu-inner {
  @media (max-width: 767px) {
    display: grid;
    height: 100%;
    grid-template-rows: auto 1fr;
  }
}

.services__menu-desc {
  display: inline-block;

  @media (max-width: 767px) {
    display: block;
    padding: 30px 45px 20px;
    margin: 0;
    font-family: 'Open Sans', 'Montserrat', sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: #2E2C2C;
    background-color: #FFFFFF;
    filter: drop-shadow(0 2px 10px fade-out(#8351FF, 0.9));
  }
}

.services__menu-list {
  display: grid;
  padding: 25px 25px 22px 25px;
  margin: 0;
  list-style: none;
  grid-template-columns: repeat(3, 140px);
  gap: 11px 15px;
  background-color: #F2F2F2;

  @media (max-width: 1099px) {
    padding: 20px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 10px;
  }

  @media (max-width: 767px) {
    padding: 110px 25px 80px;
    align-content: start;
    background-color: #F4F8FB;
  }
}

.services__menu-item {
  position: relative;
  min-height: 78px;

  @media (max-width: 1099px) {
    position: static;
    min-height: auto;
  }
}

.services__menu-link {
  position: relative;
  display: grid;
  box-sizing: border-box;
  height: 100%;
  min-height: 78px;
  padding: 5px 5px 6px;
  border-radius: 5px;
  grid-template-rows: 31px 1fr;
  place-items: center;
  text-decoration: none;
  color: inherit;
  background-color: #FFFFFF;
  filter: drop-shadow(0 2px 5px fade-out(#ADADAD, 0.85));
  transition-property: color, background-color;
  transition-duration: 100ms;
  cursor: pointer;

  &:hover {
    color: #FFFFFF;
    background-color: #D971B0;
  }

  &--current {
    // position: absolute;
    // top: -7px;
    // left: -10px;
    // width: calc(100% + 20px);
    // height: calc(100% + 14px);
    // font-size: 15px;
    // line-height: 20px;
    font-weight: 700;
    color: #FFFFFF;
    background-color: #8351FF;
    pointer-events: none;

    @media (max-width: 1099px) {
      position: static;
      width: 100%;
      height: 100%;
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media (max-width: 1099px) {
    min-height: 56px;
    grid-template-rows: auto;
  }
}

.services__menu-icon {
  order: -1;
  opacity: 0.2;
  width: auto;
  height: auto;
  max-width: 31px;
  max-height: 31px;

  @media (max-width: 1099px) {
    display: none;
  }
}

.services__menu-link:hover .services__menu-icon,
.services__menu-link--current .services__menu-icon {
  opacity: 1;
  filter: brightness(0%) invert(100%);
}

.services__slider {
  grid-area: 2 / 3 / 4 / 4;
  box-sizing: border-box;
  max-width: 100%;
  padding: 40px 30px 45px 60px;
  border-radius: 18px;
  background-color: #FFFFFF;

  @media (max-width: 1279px) {
    grid-area: 2 / 3 / 4 / 5;
  }

  @media (max-width: 1099px) {
    grid-area: 2 / 3 / 4 / 5;
    padding-top: 60px;
  }

  @media (max-width: 767px) {
    grid-area: auto;
    padding: 49px 25px 30px;
    border-radius: 12px;
    text-align: center;
  }
}

.services__slider-button.arrow-button {
  display: none;

  @media (max-width: 767px) {
    display: inline-grid;
  }
}

.services__slider .services__slider-button {
  position: absolute;
}

.services__slider-button {
  top: 60px;
  z-index: 2;

  &--prev {
    left: 35px;
    transform: rotate(180deg);
  }

  &--next {
    right: 35px;
  }
}

.services__list {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 20px;
  line-height: 29px;
  color: #2E2C2C;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 21px;
  }
}

.services__item {
  display: flex;
  height: auto;
  flex-direction: column;
}

.services__icon {
  display: none;

  @media (max-width: 767px) {
    order: -1;
    display: block;
    height: 47px;
    width: 140px;
    margin: 0 auto 27px;
    object-fit: scale-down;
    object-position: center;
  }
}

.services__heading {
  margin: 0 0 20px;
  font-family: 'Open Sans', 'Montserrat', sans-serif;
  font-size: 39px;
  line-height: 40px;
  font-weight: 700;
  color: #24007D;

  @media (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 24px;
  }
}

.services__lead {
  order: -1;
  max-width: 280px;
  margin: 0 0 20px;
  font-family: 'Open Sans', 'Montserrat', sans-serif;
  font-size: 20px;
  line-height: 29px;
  font-weight: 500;
  color: #764DDC;

  @media (max-width: 767px) {
    display: none;
  }
}

.services__text {
  margin: 0 0 18px;
  text-align: left;

  &:last-of-type {
    margin-bottom: 74px;

    @media (max-width: 767px) {
      margin-bottom: 38px;
    }
  }
}

.services__buttons {
  display: flex;
  margin: auto 0 0;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.services__button {
  min-width: 200px;
  margin: 0 0 0 30px;


  &:first-child {
    margin-left: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    max-width: 300px;
    margin-left: 0;
    margin-top: 10px;

    &:last-child {
      margin-top: 0;
    }
  }
}

.services__button.services__button--all {
  display: none;

  @media (max-width: 767px) {
    display: inline-block;
    margin: 0 0 24px;
  }
}

// button

.button {
  display: inline-block;
  box-sizing: border-box;
  padding: 16px 20px 14px;
  border: 1px solid #8351FF;
  border-radius: 6px;
  text-align: center;
  font-family: "PT Sans", "Arial", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: #FFFFFF;
  background-color: #8351FF;
  filter: drop-shadow(0 0 10px fade-out(#8351FF, 0.24));
  cursor: pointer;

  &--accent {
    border-color: #D971B0;
    background-color: #D971B0;
    filter: drop-shadow(0 0 10px fade-out(#D971B0, 0.24));
  }

  &--alter {
    border-color: currentColor;
    color: #8351FF;
    background-color: #FFFFFF;
    filter: drop-shadow(0 0 10px fade-out(#D971B0, 0.24));
  }

  @media (max-width: 767px) {
    padding-top: 18px;
    padding-bottom: 16px;
    font-size: 16px;

  }

  &:hover {
    border-color: #8351FF;
    background: #fff;
    color: #8351FF;
  }

  &--alter:hover {
    border-color: #8351FF;
    color: #FFFFFF;
    background-color: #8351FF;
    filter: drop-shadow(0 0 10px fade-out(#D971B0, 0.24));
  }
}

// arrow-button

.arrow-button {
  position: relative;
  display: inline-grid;
  box-sizing: border-box;
  padding: 2px 3px;
  border: none;
  place-items: center;
  background-color: transparent;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }

  &::after {
    content: "";
    width: 14px;
    height: 16px;
    mask-image: url('../img/img-front-page/new-front-page/arrow-right.svg');
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: #8351FF;
  }
}

// background

.background {
  position: relative;
  border-radius: 12px;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: fade-out(#8351FF, 0.5);
  }
}

.background__img-wrapper {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.background__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}




/////////////////////
.swiper-gallary {
  // padding-bottom: 0 !important;

  .container {
    max-width: 1200px;
  }

  .swiper_thumbnail .swiper-pag-container {
    padding-top: 30px;
  }

  .thumbnail-gallary {

    .swiper-pagination-progressbar.swiper-pagination-horizontal {
      height: 3px;
      width: 90%;
      position: relative;

    }

    .swiper-button-next,
    .swiper-button-prev {
      position: relative;
      margin-top: 0;
      margin: 15px;
      left: none;
      right: none;
    }
  }
}


#gallerys-thumps {

  .photos {
    cursor: pointer;
  }
  
  .centered {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
    color: #fff;
    width: 90%;
    font-family: 'Open Sans', 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;

    @media (max-width: 969px) {
      font-size: 14px;
    }
  }

  .photos {
    margin: 0 0 20px 0;
    overflow: hidden;
    text-align: center;
    border-radius: 8px;
  }

  .photos .conta {
    display: block;
    float: left;
    position: relative;
    line-height: 0;
  }

  .photos .conta:after {
    content: "";
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .photos .conta:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
    background: linear-gradient(360deg, #8351FF 4.72%, rgba(131, 81, 255, 0.5) 73.11%);
    border-radius: 8px;
    opacity: 0.8;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }


  .mySwiper2 {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .mySwiper2 .swiper-slide {
    width: 25%;
    height: 100%;
    // opacity: 0.4;
  }

  .mySwiper2 .swiper-slide-thumb-active {
    // opacity: 1;

    .photos .conta:after {
      background: linear-gradient(360deg, #8351FF 4.72%, rgba(255, 0, 89, 0.5) 73.11%);
      border: 1px solid #0000ff;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  @media (max-width: 992px) {
    .mySwiper2 {
      display: none;
    }
  }
}

.contactform.callback-form {}

.contactform.callback-form h2 {
  font-family: 'Open Sans', 'Montserrat', sans-serif;
  font-size: 39px;
  font-weight: 700;
  line-height: 41px;
}


.slides-2,
.slides-4 {
  padding-right: 10px;
  padding-left: 10px;
}

.slides-4 {
  .card {
    background-color: #F4F8FB;

    .card-text {
      padding-top: 1.25rem;
      line-height: 20px;
    }
  }
}

.slides-2 {
  .card-text {
    line-height: 20px;
  }
}

section.testimonials .slides-4 .card {
  box-shadow: none;
}

.slides-3 {
  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    margin-left: 20px;
  }
}


.slides-1 {
  .client .wrapp {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.slides-texts {
  .block-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;

    .heading {
      max-width: 500px;
    }
  }
}




.video-player-container {
  position: relative;

  img {
    width: 100%;
  }
}

.video-player-button {
  position: absolute;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 0px rgba(43, 43, 43, 0.2);
  background-color: #FFFFFF;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 1;
  cursor: pointer;
  background-image: url("../img/video-gallery/play-button.svg");
  background-repeat: no-repeat;
  background-size: 32% auto;
  background-position: 55% 50%;
  opacity: 0.85;

  &--small {
    background-size: 24% auto;
    width: 50px;
    height: 50px;
  }

  &:hover {
    opacity: 1;
  }
}

.video-player {
  width: 100%;

  &--main {
    box-shadow: 0px 0px 20px 4px rgba(43, 43, 43, 0.14);
  }
}

.video-content {
  font-family: 'PT Sans';
  font-style: normal;
  line-height: 145%;
  color: #444444;
  padding: 50px;

  @media (max-width: 992px) {
    padding: 20px;
  }
}

.video-content-header {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 30px;
}

.video-content-text {
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  line-height: 20px;

  @media (max-width: 992px) {
    line-height: 17px;
  }
}

// .testimonials p.white-color {
//   line-height: 20px;;
// }