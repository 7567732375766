@font-face {
    font-family: 'PT Sans';
    src: url('../fonts/PTSans-Regular.eot');
    src: url('../fonts/PTSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PTSans-Regular.woff2') format('woff2'),
        url('../fonts/PTSans-Regular.woff') format('woff'),
        url('../fonts/PTSans-Regular.ttf') format('truetype'),
        url('../fonts/PTSans-Regular.svg#PTSans-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../fonts/PTSans-Bold.eot');
    src: url('../fonts/PTSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PTSans-Bold.woff2') format('woff2'),
        url('../fonts/PTSans-Bold.woff') format('woff'),
        url('../fonts/PTSans-Bold.ttf') format('truetype'),
        url('../fonts/PTSans-Bold.svg#PTSans-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Light.eot');
    src: url('../fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Light.woff2') format('woff2'),
        url('../fonts/Montserrat-Light.woff') format('woff'),
        url('../fonts/Montserrat-Light.ttf') format('truetype'),
        url('../fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.eot');
    src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat-Regular.woff') format('woff'),
        url('../fonts/Montserrat-Regular.ttf') format('truetype'),
        url('../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Medium.eot');
    src: url('../fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Medium.woff2') format('woff2'),
        url('../fonts/Montserrat-Medium.woff') format('woff'),
        url('../fonts/Montserrat-Medium.ttf') format('truetype'),
        url('../fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.eot');
    src: url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat-SemiBold.woff') format('woff'),
        url('../fonts/Montserrat-SemiBold.ttf') format('truetype'),
        url('../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.eot');
    src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat-Bold.woff') format('woff'),
        url('../fonts/Montserrat-Bold.ttf') format('truetype'),
        url('../fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-ExtraBold.eot');
    src: url('../fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-ExtraBold.woff2') format('woff2'),
        url('../fonts/Montserrat-ExtraBold.woff') format('woff'),
        url('../fonts/Montserrat-ExtraBold.ttf') format('truetype'),
        url('../fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Black.eot');
    src: url('../fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Black.woff2') format('woff2'),
        url('../fonts/Montserrat-Black.woff') format('woff'),
        url('../fonts/Montserrat-Black.ttf') format('truetype'),
        url('../fonts/Montserrat-Black.svg#Montserrat-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Bold.eot');
    src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans-Bold.woff') format('woff'),
        url('../fonts/OpenSans-Bold.ttf') format('truetype'),
        url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-BoldItalic.eot');
    src: url('../fonts/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-BoldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-BoldItalic.woff') format('woff'),
        url('../fonts/OpenSans-BoldItalic.ttf') format('truetype'),
        url('../fonts/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-LightItalic.eot');
    src: url('../fonts/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-LightItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-LightItalic.woff') format('woff'),
        url('../fonts/OpenSans-LightItalic.ttf') format('truetype'),
        url('../fonts/OpenSans-LightItalic.svg#OpenSans-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-ExtraBold.eot');
    src: url('../fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../fonts/OpenSans-ExtraBold.woff') format('woff'),
        url('../fonts/OpenSans-ExtraBold.ttf') format('truetype'),
        url('../fonts/OpenSans-ExtraBold.svg#OpenSans-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Italic.eot');
    src: url('../fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Italic.woff2') format('woff2'),
        url('../fonts/OpenSans-Italic.woff') format('woff'),
        url('../fonts/OpenSans-Italic.ttf') format('truetype'),
        url('../fonts/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Medium.eot');
    src: url('../fonts/OpenSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Medium.woff2') format('woff2'),
        url('../fonts/OpenSans-Medium.woff') format('woff'),
        url('../fonts/OpenSans-Medium.ttf') format('truetype'),
        url('../fonts/OpenSans-Medium.svg#OpenSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Light.eot');
    src: url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Light.woff2') format('woff2'),
        url('../fonts/OpenSans-Light.woff') format('woff'),
        url('../fonts/OpenSans-Light.ttf') format('truetype'),
        url('../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-ExtraBoldItalic.eot');
    src: url('../fonts/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype'),
        url('../fonts/OpenSans-ExtraBoldItalic.svg#OpenSans-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Regular.eot');
    src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/OpenSans-Regular.woff') format('woff'),
        url('../fonts/OpenSans-Regular.ttf') format('truetype'),
        url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-SemiBold.eot');
    src: url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../fonts/OpenSans-SemiBold.woff') format('woff'),
        url('../fonts/OpenSans-SemiBold.ttf') format('truetype'),
        url('../fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-SemiBoldItalic.eot');
    src: url('../fonts/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-SemiBoldItalic.woff') format('woff'),
        url('../fonts/OpenSans-SemiBoldItalic.ttf') format('truetype'),
        url('../fonts/OpenSans-SemiBoldItalic.svg#OpenSans-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-MediumItalic.eot');
    src: url('../fonts/OpenSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-MediumItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-MediumItalic.woff') format('woff'),
        url('../fonts/OpenSans-MediumItalic.ttf') format('truetype'),
        url('../fonts/OpenSans-MediumItalic.svg#OpenSans-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

